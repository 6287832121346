import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
// import ReactImageMagnify from "react-image-magnify";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../Redux/common/action";
import MenuOutSideClick from "./OutSide";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
// import { URL } from "../../Redux/common/url";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { Image } from "react-bootstrap";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import axios from "axios";
// import Image from "next/image";

function HeaderMain({ footerData }) {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const id = useLocation();

  const [serachBoxShow, setserachBoxShow] = useState(false);
  const router = useNavigate();
  const [menu, setmenu] = useState(false);
  const [ctindex, setctindex] = useState(0);
  const [selectedCate, setselectedCate] = useState(0);
  const [dispImg, setDispImg] = useState("/assets/imgs/shop/product-16-2.jpg");
  const hendleViewcate = (st, index) => {
    setmenu(st);
    setctindex(st);
  };
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);

  const get_footer_all_data = footerData?.data;

  const get_footer_all_data_1 = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  const serach_pro_pub = useSelector((state) =>
    state?.allapi?.serach_pro_pub ? state?.allapi?.serach_pro_pub : []
  );
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_user_profile ? state?.allapi?.get_user_profile : {}
  );
  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );

  console.log(get_buss_pro_1);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);

  if (typeof window !== "undefined" && Object.keys(window).length > 0) {
    const blogids = localStorage.getItem("cart_product");

    var arr = JSON.parse(blogids && blogids);
    var arrss = localStorage.getItem("access_token")
      ? get_all_cart_itme && get_all_cart_itme
      : arr;
  }

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("access_token")) {
        // getcartitme

        dispatch(
          allapiAction.getcartitme({
            data: arrss,
            // userid: get_user_profile?.id,
          })
        );
      } else {
        dispatch(
          allapiAction.getcartProduct({
            data: arrss,
            // userid: get_user_profile?.id,
          })
        );
      }
    }, 500);

    return () => {};
  }, []);

  // afterLoginAddCart

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      if (arr?.length > 0) {
        arr?.forEach((file) => {
          dispatch(allapiAction.afterLoginAddCart(file));

          var lists = arr.filter((x) => {
            return x.product_id != file?.product_id;
          });

          if (lists?.length == 0) {
            localStorage.removeItem("cart_product");
          } else {
            localStorage.setItem("cart_product", JSON.stringify(lists));
          }

          return () => {};
        });
      }
    }
  }, [get_user_profile]);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 150,
    vertical: true,
  };

  useEffect(() => {
    dispatch(allapiAction.getCategory({}));
    return () => {};
  }, []);

  const imageList = [
    "/assets/imgs/shop/product-16-2.jpg",
    "/assets/imgs/shop/product-16-1.jpg",
    "/assets/imgs/shop/product-16-3.jpg",
    "/assets/imgs/shop/product-16-4.jpg",
    "/assets/imgs/shop/product-16-5.jpg",
    // "/assets/imgs/shop/product-16-6.jpg",
    // "/assets/imgs/shop/product-16-7.jpg",
  ];
  const [mobilesearch, setmobilesearch] = useState(false);
  const [mobilesearchdata, setmobilesearchdata] = useState("");

  console.log(mobilesearchdata);
  const hendlesearchProduct = (e) => {
    // e
    setmobilesearchdata(e?.target?.value);
    if (e.key === "Enter") {
      // Prevent page reload on form submission

      e.preventDefault();
      setAnchor1(false);
      setAnchor2(false);
      setmobilesearch(false);
      setmenu(false);
      setserachBoxShow(false);
      // Redirect to the new search URL
      router(`/products?searchp=${e?.target?.value}`);
    }

    dispatch(allapiAction.searchProductpub({ key: e?.target?.value }));
    return () => {};
  };
  const hendlesearchProduct2 = (e) => {
    // e

    // if (e.key === "Enter") {
    // Prevent page reload on form submission

    e.preventDefault();
    setAnchor1(false);
    setAnchor2(false);
    setmobilesearch(false);
    setmenu(false);
    setserachBoxShow(false);
    // Redirect to the new search URL
    router(`/products?searchp=${mobilesearchdata}`);
    // }

    dispatch(allapiAction.searchProductpub({ key: mobilesearchdata }));
    return () => {};
  };

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      if (localStorage.getItem("access_token")) {
        // 1
        dispatch(allapiAction.getBussProfile1({}));
      }
    } else {
      setValue("");
    }
  }, []);

  const [maincateindex, setmaincateindex] = useState("");
  const [subcateindex, setsubncateindex] = useState("0");

  const hendletocateShow = (e) => {
    setmaincateindex(e);
    setsubncateindex("0");
  };

  const hendleTochangeRoute = (e) => {
    setAnchor1(false);
    setmobilesearch(false);
    router("/product-details/" + e);
  };
  console.log(all_categories_List);

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  console.log(scrollTop);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const [isHovered, setIsHovered] = useState(false);
  const [subcat, setsubcat] = useState([]);
  const [subcatind, setsubcatind] = useState(0);
  console.log(isHovered);
  console.log("ctindex", ctindex);
  const handleMouseOver = (data, subin) => {
    console.log(data);
    setsubcat(data);
    setsubcatind(subin);
    setIsHovered(true);
  };
  const handleMouseOver2 = (data) => {
    console.log(data);
    // console.log(all_categories_List[data]?.subcates[0]?.subjectss);
    setsubcatind(0);
    setsubcat(all_categories_List[data]?.subcates[0]?.subjectss);
    // setIsHovered(true);
  };
  return (
    <div className={scrollTop > 30 ? "mheader" : ""}>
      <div
        id="menufade"
        onClick={() => {
          setmenu(false);
        }}
        className={menu ? "sideNav-overlay" : ""}
      ></div>
      {serachBoxShow && (
        <div class="rui search-modal-close">
          <button
            class="rui btn btn-default solid icon-only variant-edit"
            type="button"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              onClick={() => {
                setserachBoxShow(false);
              }}
            >
              <i class="rui font-icon fa-lg fa fa-times"></i>
            </div>
          </button>
        </div>
      )}
      {serachBoxShow && (
        <div class="rui search-modal-header">
          <form class="rui search-modal-input" role="search" autocomplete="off">
            <i class="fa fa-search search-icon"></i>
            <div class="suggestions">
              <div
                role="combobox"
                aria-haspopup="listbox"
                aria-owns="react-autowhatever-1"
                aria-expanded="false"
                class="react-autosuggest__container"
              >
                <input
                  type="text"
                  autocomplete="off"
                  aria-autocomplete="list"
                  aria-controls="react-autowhatever-1"
                  class="react-autosuggest__input"
                  placeholder="Search"
                  // value=""
                  onChange={(e) => {
                    hendlesearchProduct(e);
                  }}
                  onKeyDown={(e) => {
                    hendlesearchProduct(e);
                  }}
                />
                <div
                  id="react-autowhatever-1"
                  role="listbox"
                  class="react-autosuggest__suggestions-container"
                ></div>
              </div>
            </div>
            <button
              class="rui btn btn-default flat search-submit"
              type="submit"
              onClick={(e) => {
                hendlesearchProduct2(e);
              }}
            >
              <div style={{ fontWeight: "normal" }}>
                <span>Submit</span>
              </div>
            </button>
          </form>
        </div>
      )}
      <div className=" p-0 m-0 two">
        <div class="container-fluid ">
          <div class="row p-0 m-0 two1">
            <div class="col-md-6 p-0 m-0 two2"></div>
            <div class="col-md-6 p-1  two3">
              <span class="float-right">
                {!value ? (
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/login"}
                  >
                    {" "}
                    SIGN IN /
                  </Link>
                ) : (
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    target={"_blank"}
                    to={"/home"}
                  >
                    {get_buss_pro_1?.fullName + " "} {/* MY ACCOUNT */}
                  </a>
                )}
                {!value ? (
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={"/register"}
                  >
                    {" "}
                    SIGN UP{" "}
                  </Link>
                ) : (
                  <></>
                )}

                {/* {!value ? (
                <ul>
                  <li>
                    <i className="fi-rs-user iconSize mr-5"></i>
                    <Link to={"/login"}>Login</Link>
                  </li>
                  <li>
                    <i className="fi-rs-shop iconSize mr-5"></i>

                    <Link to={"/register"}>Register</Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <i className="fi-rs-user iconSize mr-5"></i>
                    <a target={"_blank"} to={"/home"}>
                      My Account
                    </a>
                  </li>
                </ul>
              )} */}
                <span style={{ color: "black" }}>
                  |{" "}
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    // target={"_blank"}
                    to={"/transparency"}
                  >
                    {" "}
                    TRANSPARENCY{" "}
                  </Link>
                  |{" "}
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    // target={"_blank"}
                    to={"/contact"}
                  >
                    {" "}
                    CUSTOMER CARE{" "}
                  </Link>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <header class="header   ">
        <div class="container">
          <div class="row v-center ">
            <div class="header-item item-left">
              <div class="logo">
                <Link to="/">
                  {/* <img src="https://asort.com/resources/logo-1.0.png" alt="" /> */}
                  {get_footer_all_data?.socialIcon?.homeLogo && (
                    <Image
                      src={
                        URL.API_BASE_URL +
                        get_footer_all_data?.socialIcon?.homeLogo
                      }
                      alt="logo"
                      width={300}
                      height={50}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/imgs/notfound.png";
                      }}
                    />
                  )}
                </Link>
              </div>
            </div>

            <div class="header-item item-center">
              <div
                class={menu ? "menu-overlay active" : "menu-overlay"}
                onClick={() => {
                  setmenu(false);
                }}
              ></div>
              <nav class={menu ? "menu  " : "menu"}>
                <div class="mobile-menu-head">
                  <div class="go-back">
                    <i class="fa fa-angle-left"></i>
                  </div>
                  <div class="current-menu-title"></div>
                  <div
                    class="mobile-menu-close"
                    onClick={() => {
                      setmenu(false);
                    }}
                  >
                    &times;
                  </div>
                </div>
                <ul class="menu-main">
                  <li class="menu-item-has-children">
                    <Link to="/">HOME </Link>
                  </li>
                  <li class="menu-item-has-children">
                    <Link to="/new-launch">NEW LAUNCH </Link>
                  </li>
                  <li class="menu-item-has-children">
                    <Link to="/last-chance">LAST CHANCE </Link>
                  </li>

                  {all_categories_List &&
                    all_categories_List?.map((data, i) => {
                      return (
                        <li class="menu-item-has-children" key={i}>
                          <Link
                            to={"/category-products/" + data?.slug}
                            onClick={() => {
                              hendleViewcate(false, i);
                            }}
                            onMouseOver={() => {
                              handleMouseOver2(i);
                            }}
                          >
                            {" "}
                            {data?.name}
                          </Link>
                          <div class="sub-menu mega-menu mega-menu-column-4">
                            <div className="col-4">
                              <h5>Shop By Category</h5>
                              <hr />
                              {data?.subcates &&
                                data?.subcates?.map((datasub, isub) => {
                                  console.log(datasub);
                                  return (
                                    <div
                                      class={
                                        subcatind == isub
                                          ? "list-item subcatactive"
                                          : "list-item"
                                      }
                                      key={isub}
                                    >
                                      <h4
                                        class="title"
                                        onMouseOver={() => {
                                          handleMouseOver(
                                            datasub?.subjectss,
                                            isub
                                          );
                                        }}
                                      >
                                        <Link
                                          to={
                                            "/sub-category-product/" +
                                            datasub?.cate_sulg
                                          }
                                        >
                                          {datasub?.name}
                                        </Link>
                                      </h4>
                                      {/* <hr style={{ border: "1px solid" }} /> */}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="col-4 subsubbg">
                              <h5>Shop By Sub-Category</h5>
                              <hr />
                              <ul>
                                {subcat &&
                                  subcat?.map((datasuject, isubject) => {
                                    return (
                                      <li key={isubject}>
                                        {/* <a to="Home.html">
                                          <div class="btn-group dropright">
                                            <span type="button"> */}
                                        <Link
                                          to={
                                            "/category-product/" +
                                            datasuject?.cate_sulg
                                          }
                                          onClick={() => {
                                            router(
                                              "/category-product/" +
                                                datasuject?.cate_sulg
                                            );
                                          }}
                                        >
                                          {datasuject?.name}
                                        </Link>
                                        {/* </span>
                                          </div>
                                        </a> */}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="col-4 topheadBox">
                              <div className="row">
                                {subcat &&
                                  subcat?.map((datasuject, isubject) => {
                                    return (
                                      isubject < 2 && (
                                        <div className="col-6" key={isubject}>
                                          <Link
                                            to={
                                              "/category-product/" +
                                              datasuject?.cate_sulg
                                            }
                                            onClick={() => {
                                              router(
                                                "/category-product/" +
                                                  datasuject?.cate_sulg
                                              );
                                            }}
                                          >
                                            <img
                                              src={
                                                URL?.API_BASE_URL +
                                                datasuject?.image
                                              }
                                              alt={datasuject?.name}
                                            />
                                          </Link>
                                        </div>
                                      )
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}

                  <li class="menu-item-has-children">
                    <Link to="/brand">BRAND </Link>
                  </li>
                  <li class="menu-item-has-children">
                    <Link to="/blogs">BLOGS </Link>
                  </li>
                  {/* <li class="menu-item-has-children">
                    <Link to="/contact">CONTACT </Link>
                  </li> */}
                </ul>
              </nav>
            </div>

            <div class="header-item item-right">
              <a
                to="#"
                onClick={() => {
                  setserachBoxShow(true);
                }}
              >
                <i class="fas fa-search"></i>
              </a>
              {/* <a to="#">
                <i class="far fa-heart"></i>
              </a> */}
              <Link
                to={!value ? "/login" : "/shop-cart"}
                className="chrtitmess"
              >
                <i class="fas fa-shopping-cart"></i>
                <span className="pro-count blue">
                  {get_all_cart_itme?.length}
                </span>
              </Link>
              {/* <Link
                              to={!value ? "/login" : "/shop-cart"}
                              className="outline"
                            >
                              View cart
                            </Link> */}

              <div
                class="mobile-menu-trigger"
                onClick={() => {
                  setmenu(true);
                }}
              >
                <span></span>
              </div>
            </div>

            <div
              className={
                menu
                  ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
                  : "mobile-header-active mobile-header-wrapper-style"
              }
            >
              <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-top">
                  <div className="mobile-header-logo">
                    <Link
                      to={"/"}
                      onClick={() => {
                        setmenu(false);
                      }}
                    >
                      {get_footer_all_data?.socialIcon?.homeLogo && (
                        <Image
                          src={
                            URL.API_BASE_URL +
                            get_footer_all_data?.socialIcon?.homeLogo
                          }
                          alt="logo"
                          width={300}
                          height={35}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/assets/imgs/notfound.png";
                          }}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button
                      className="close-style search-close"
                      onClick={() => {
                        setmenu(false);
                      }}
                    >
                      <i className="icon-top"></i>
                      <i className="icon-bottom"></i>
                    </button>
                  </div>
                </div>
                <div className="mobile-header-content-area">
                  <div className="mobile-search search-style-3 mobile-header-border search-sec">
                    {/* <form action="#"> */}
                    {/* <input
                type="text"
                placeholder="Search for product"
                ref={anchorRef2}
                onChange={(e) => {
                  hendlesearchProduct(e);
                }}
                onKeyDown={(e) => {
                  hendlesearchProduct(e);
                }}
              /> */}
                    {/* 
              {anchor2 && (
                <div
                  className="searchuniversitiesandcourses"
                  style={{ padding: "0px" }}
                >
                  <ul>
                    {serach_pro_pub &&
                      serach_pro_pub?.map((data, i) => {
                        return (
                          <li
                            onClick={() => {
                              setmenu(false);

                              router.push("/product-details/" + data?.pro_sulg);
                            }}
                            key={i}
                          >
                            {data?.title}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )} */}
                  </div>
                  <div className="mobile-menu-wrap mobile-header-border">
                    {/* <!-- mobile menu start --> */}
                    <nav>
                      {/* <Link to={"/"}>Home</Link> */}

                      <ul className="mobile-menu font-heading">
                        <li class="menu-item-has-children">
                          <Link
                            to="/"
                            onClick={() => {
                              setmenu(false);
                            }}
                          >
                            HOME{" "}
                          </Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link
                            to="/new-launch"
                            onClick={() => {
                              setmenu(false);
                            }}
                          >
                            NEW LAUNCH{" "}
                          </Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link
                            to="/last-chance"
                            onClick={() => {
                              setmenu(false);
                            }}
                          >
                            LAST CHANCE{" "}
                          </Link>
                        </li>
                      </ul>

                      <ul className="mobile-menu font-heading">
                        {all_categories_List?.map((maincateData, inmain) => {
                          return (
                            <li
                              className={
                                maincateindex == inmain
                                  ? "menu-item-has-children active"
                                  : "menu-item-has-children"
                              }
                              key={inmain}
                            >
                              <span className="menu-expand">
                                <i className="fi-rs-angle-small-down"></i>
                              </span>
                              <a
                                onClick={() => {
                                  hendletocateShow(inmain);
                                }}
                              >
                                {maincateData?.name}
                              </a>
                              <ul
                                className="dropdown"
                                style={
                                  maincateindex == inmain
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                {maincateData?.subcates &&
                                  maincateData?.subcates?.map(
                                    (datasub, subind) => {
                                      return (
                                        <li
                                          className={
                                            subind == subcateindex
                                              ? "menu-item-has-children active  "
                                              : "menu-item-has-children"
                                          }
                                          key={subind}
                                        >
                                          <span className="menu-expand">
                                            <i className="fi-rs-angle-small-down"></i>
                                          </span>
                                          <a
                                            to="#"
                                            onClick={() => {
                                              setsubncateindex(subind);
                                            }}
                                          >
                                            {datasub?.name}
                                          </a>

                                          <ul
                                            className="dropdown"
                                            style={
                                              subind == subcateindex
                                                ? {}
                                                : { display: "none" }
                                            }
                                          >
                                            {datasub?.subjectss &&
                                              datasub?.subjectss?.map(
                                                (data, i) => {
                                                  return (
                                                    i < 9 && (
                                                      <li key={i}>
                                                        <a
                                                          onClick={() => {
                                                            router(
                                                              "/category-product/" +
                                                                data?.cate_sulg
                                                            );
                                                            setmenu(false);
                                                          }}
                                                        >
                                                          {data?.name}
                                                        </a>
                                                      </li>
                                                    )
                                                  );
                                                }
                                              )}
                                            <li>
                                              {datasub?.subjectss?.length >
                                                9 && (
                                                <Link
                                                  to={"/category"}
                                                  className="tabBtn1 active"
                                                >
                                                  more
                                                </Link>
                                              )}
                                            </li>
                                          </ul>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                      <ul className="mobile-menu font-heading">
                        <li class="menu-item-has-children">
                          <Link
                            to="/brand"
                            onClick={() => {
                              setmenu(false);
                            }}
                          >
                            BRAND{" "}
                          </Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link
                            to="/blogs"
                            onClick={() => {
                              setmenu(false);
                            }}
                          >
                            BLOGS
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    {/* <!-- mobile menu end --> */}
                  </div>
                  <hr />
                  {/* <div className="mobile-header-info-wrap">
                    {!value && (
                      <div className="single-mobile-header-info">
                        <a
                          onClick={() => {
                            router.push("/login");
                            setmenu(false);
                          }}
                          to="/login"
                        >
                          Login
                        </a>
                      </div>
                    )}

                    {!value && (
                      <div className="single-mobile-header-info">
                        <a
                          onClick={() => {
                            router.push("/register");
                            setmenu(false);
                          }}
                        >
                          Register
                        </a>
                      </div>
                    )}
                    {value && (
                      <div className="single-mobile-header-info">
                        <a
                          onClick={() => {
                            router.push("/home");
                            setmenu(false);
                          }}
                        >
                          My Account
                        </a>
                      </div>
                    )}
                    <div className="single-mobile-header-info">
                      <a to="#">
                        <i className="fi-rs-marker"></i> Our location{" "}
                      </a>
                    </div>

                    <div className="single-mobile-header-info">
                      <a to="#">
                        <i className="fi-rs-headphones"></i>
                        {get_footer_all_data?.socialIcon?.number}
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderMain;
