import React from "react";
import Sliders from "../Home/Slider";
import HeaderMain from "../Layout/HeaderMain";
import HomeNews from "./HomeNews";
import HomeSlider from "./HomeSlider";

function HomeNewCom({ cat, sliderdate }) {
  return (
    <div>
      <HeaderMain />
      <Sliders sliderdate={sliderdate} />
      {/* <HomeSlider /> */}
      <HomeNews />
    </div>
  );
}

export default HomeNewCom;
