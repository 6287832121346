// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HeaderMain from "../Layout/HeaderMain";

function Checkout() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const loading_checkOut = useSelector((state) =>
    state?.allapi?.loading_checkOut ? state?.allapi?.loading_checkOut : false
  );

  const get_user_profile = useSelector((state) =>
    state?.allUserRegister?.get_user_profile
      ? state?.allUserRegister?.get_user_profile
      : {}
  );
  console.log(get_all_cart_itme);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);
  // createOrder
  const dispatch = useDispatch();

  const router = useNavigate();

  const orderSumit = (e) => {
    // createOrder

    // if (PaymentMethod == "online") {
    //   displayRazorpay();
    // } else {

    const orderAddress = localStorage.getItem("order_Address");

    console.log(orderAddress);
    const obj = JSON.parse(orderAddress);
    console.log(obj);

    dispatch(
      allapiAction.createOrder(
        {
          user_name: get_user_profile?.name,
          user_email: get_user_profile?.email,
          user_mobile: get_user_profile?.mobile_no,
          delivery_charges: "0",
          sub_total: totalSubtotalPrice,
          address_id: obj,
          payment_mode_name: "wallet",
          // products_ids: commentsData,
        },
        router
      )
    );
    return () => {};
    // }
  };
  const shippingCharge = totalSubtotalPrice > 10000 ? 699 : 199;

  const totalAmount =
    totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;
  return (
    <div>
      <HeaderMain />
      {loading_checkOut ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BounceLoader color="#fd9b57" />
        </div>
      ) : (
        <main className="main">
          <div className="page-header breadcrumb-wrap">
            <div className="container">
              <div className="breadcrumb">
                <Link to="/" rel="nofollow">
                  <i className="fi-rs-home mr-5"></i>home
                </Link>
                <span></span> Shop
                <span></span> Checkout
              </div>
            </div>
          </div>
          <div className="container mb-80 mt-50">
            <div className="row">
              <div className="col-lg-8 mb-40">
                <h1 className="heading-2 mb-10">Checkout</h1>
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    There are{" "}
                    <span className="text-brand">
                      {get_all_cart_itme?.length}
                    </span>{" "}
                    products in your cart
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="border p-40 cart-totals ml-30 mb-50">
                  <div className="d-flex align-items-end justify-content-between mb-30">
                    <h4>Your Order</h4>
                    <h6 className="text-muted">Subtotal</h6>
                  </div>
                  <div className="divider-2 mb-30"></div>
                  <div className="table-responsive order_table checkout">
                    <table className="table no-border">
                      <tbody>
                        {get_all_cart_itme &&
                          get_all_cart_itme?.map((data, i) => {
                            return (
                              <tr key={i}>
                                <td className="image product-thumbnail">
                                  <img
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                  />
                                </td>
                                <td>
                                  <h6 className="w-160 mb-5">
                                    <Link
                                      to={"/product-details/" + data?.pro_sulg}
                                      className="text-heading"
                                    >
                                      {data?.title}
                                    </Link>
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="text-muted pl-20 pr-20">
                                    {data?.productcolorId?.color}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="text-muted pl-20 pr-20">
                                    {data?.size}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="text-muted pl-20 pr-20">
                                    x {data?.item}
                                  </h6>
                                </td>
                                <td>
                                  <h4 className="text-brand">
                                    ₹{data?.subtotalprice}
                                  </h4>
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr>
                        <td className="image product-thumbnail">
                          <img src="assets/imgs/shop/product-2-1.jpg" alt="#" />
                        </td>
                        <td>
                          <h6 className="w-160 mb-5">
                            <a
                              href="shop-product-full.html"
                              className="text-heading"
                            >
                              Seeds of Change Organic Quinoa
                            </a>
                          </h6>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div
                                className="product-rating"
                                style={{ width: "90%" }}
                              ></div>
                            </div>
                            <span className="font-small ml-5 text-muted">
                              {" "}
                              (4.0)
                            </span>
                          </div>
                        </td>
                        <td>
                          <h6 className="text-muted pl-20 pr-20">x 1</h6>
                        </td>
                        <td>
                          <h4 className="text-brand">$15.0</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="image product-thumbnail">
                          <img src="assets/imgs/shop/product-3-1.jpg" alt="#" />
                        </td>
                        <td>
                          <h6 className="w-160 mb-5">
                            <a
                              href="shop-product-full.html"
                              className="text-heading"
                            >
                              Angie’s Boomchickapop Sweet{" "}
                            </a>
                          </h6>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div
                                className="product-rating"
                                style={{ width: "90%" }}
                              ></div>
                            </div>
                            <span className="font-small ml-5 text-muted">
                              {" "}
                              (4.0)
                            </span>
                          </div>
                        </td>
                        <td>
                          <h6 className="text-muted pl-20 pr-20">x 1</h6>
                        </td>
                        <td>
                          <h4 className="text-brand">$17.2</h4>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="border p-md-4 cart-totals ml-30">
                  <div className="table-responsive">
                    <table className="table no-border">
                      <tbody>
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="text-muted">Subtotal</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h4 className="text-brand text-end">
                              ₹{totalSubtotalPrice}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td scope="col" colspan="2">
                            <div className="divider-2 mt-10 mb-10"></div>
                          </td>
                        </tr>
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="text-muted">Shipping</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h5 className="text-heading text-end">
                              {totalSubtotalPrice > 0 && shippingCharge}
                            </h5>
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="text-muted">gst</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h5 className="text-heading text-end">
                              {totalSubtotalPricegst}
                            </h5>
                          </td>{" "}
                        </tr>
                        <tr>
                          <td scope="col" colspan="2">
                            <div className="divider-2 mt-10 mb-10"></div>
                          </td>
                        </tr>
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="text-muted">Total</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h4 className="text-brand text-end">
                              ₹{totalSubtotalPrice > 0 && totalAmount}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <Link href="/checkout-address" className="btn mb-20 w-100">
                  Proceed To CheckOut<i className="fi-rs-sign-out ml-15"></i>
                </Link> */}
                  <a
                    onClick={() => {
                      orderSumit();
                    }}
                    className="btn mb-20 w-100"
                  >
                    Place an Order<i className="fi-rs-sign-out ml-15"></i>
                  </a>
                </div>

                {/* <div className="row">
                <h4 className="mb-30">Place Order</h4>
                <div className="payment ml-30">
                 
                  <a
                    onClick={() => {
                      orderSumit();
                    }}
                    className="btn btn-fill-out btn-block mt-30"
                  >
                    Place an Order<i className="fi-rs-sign-out ml-15"></i>
                  </a>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export default Checkout;
