import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { allapiAction } from "../../Redux/common/action";
import ReactPaginate from "react-paginate";
// import { URL } from "../../Redux/common/url";
import moment from "moment";
// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Header from "../../component/layout/Header";
import { allapiAction } from "../../Redux/common/action";
import HeaderMain from "../Layout/HeaderMain";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { URL } from "../../Redux/common/url";
// import Footer from "../../component/layout/Footer";
// import Link from "next/link";
// import { NextSeo } from "next-seo";
// import Image from "next/image";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Seo from "../Seo/Seo";

function Blogs() {
  const dispatch = useDispatch();

  const blog_list = useSelector((state) =>
    state?.allapi?.blog_list ? state?.allapi?.blog_list : {}
  );

  const [itemsPerPage, setitemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [pageNo, setpageNo] = useState(1);

  const comments = blog_list?.docs ? blog_list?.docs : [];
  const pageCount = blog_list?.totalPages ? blog_list?.totalPages : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice

    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  useEffect(() => {
    // getBlogListPub
    dispatch(allapiAction.getBlogListPub(1));
    return () => {};
  }, []);

  const handlePageClick = (event) => {
    const datad = event?.selected + 1;

    setpageNo(datad);
    dispatch(allapiAction.getBlogListPub(datad));
    return () => {};
  };

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }
  const urltet = URL.API_BASE_URL_2 + "/blogs";
  return (
    <div>
      {/* <NextSeo
        title={"blogs"}
        description={"blogs"}
        canonical={urltet}
        openGraph={{
          // type: "website",
          locale: "en",

          siteName: "Jewelslly",
          url: urltet,
          title: "blogs",
          description: "blogs",
          type: "website",
          // profile: {
          //   firstName: post?.buseniss_id?.businessName,
          //   city: post?.city,
          //   country: post?.country,
          //   address: post?.buseniss_id?.address,
          //   // lastName: "Last",
          //   // username: "firstlast123",
          //   // gender: "female",
          // },
          // images: [
          //   {
          //     url: URL.API_BASE_URL + post?.buseniss_id?.logo,
          //     width: 800,
          //     height: 600,
          //     alt: post?.buseniss_id?.businessName,
          //     type: "image/jpeg",
          //   },
          // ],
        }}
        twitter={{
          handle: "@handle",
          site: "@jewelslly",
          cardType: "summary_large_image",
          title: "blogs",
          description: "blogs",
        }}
      /> */}
      <HeaderMain />
      {/* <Seo
        pagLink={pageUrl}
        // title={get_page_details?.title}
        // titleSeo={get_page_details?.metaTag}
        // seo_descriptions={get_page_details?.metadescription}
      /> */}
      {/* <Header /> */}
      <main className="main">
        <div className="page-header mt-30 mb-75">
          <div className="container">
            <div className="">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <h1 className="mb-15">Blog </h1>
                  <div className="breadcrumb">
                    {/* <a href="index.html" rel="nofollow">
                    </a> */}
                    <Link to={"/"} rel="nofollow">
                      <i className="fi-rs-home mr-5"></i>home
                    </Link>
                    <span></span> Blog
                  </div>
                </div>
                <div className="col-xl-9 text-end d-none d-xl-block">
                  {/* <ul className="tags-list">
                    <li className="hover-up">
                      <a href="blog-category-grid.html">
                        <i className="fi-rs-cross mr-10"></i>Shopping
                      </a>
                    </li>
                    <li className="hover-up active">
                      <a href="blog-category-grid.html">
                        <i className="fi-rs-cross mr-10"></i>Recips
                      </a>
                    </li>
                    <li className="hover-up">
                      <a href="blog-category-grid.html">
                        <i className="fi-rs-cross mr-10"></i>Kitchen
                      </a>
                    </li>
                    <li className="hover-up">
                      <a href="blog-category-grid.html">
                        <i className="fi-rs-cross mr-10"></i>News
                      </a>
                    </li>
                    <li className="hover-up mr-0">
                      <a href="blog-category-grid.html">
                        <i className="fi-rs-cross mr-10"></i>Food
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content mb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="shop-product-fillter mb-50">
                  <div className="totall-product">
                    <h2>
                      <img
                        className="w-36px mr-10"
                        src="assets/imgs/theme/icons/category-1.svg"
                        alt=""
                      />
                      Recips Articles
                    </h2>
                  </div>
                  <div className="sort-by-product-area">
                    <div className="sort-by-cover mr-10">
                      <div className="sort-by-product-wrap">
                        <div className="sort-by">
                          <span>
                            <i className="fi-rs-apps"></i>Show:
                          </span>
                        </div>
                        <div className="sort-by-dropdown-wrap">
                          <span>
                            {" "}
                            50 <i className="fi-rs-angle-small-down"></i>
                          </span>
                        </div>
                      </div>
                      <div className="sort-by-dropdown">
                        <ul>
                          <li>
                            <a className="active" href="#">
                              50
                            </a>
                          </li>
                          <li>
                            <a href="#">100</a>
                          </li>
                          <li>
                            <a href="#">150</a>
                          </li>
                          <li>
                            <a href="#">200</a>
                          </li>
                          <li>
                            <a href="#">All</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="sort-by-cover">
                      <div className="sort-by-product-wrap">
                        <div className="sort-by">
                          <span>
                            <i className="fi-rs-apps-sort"></i>Sort:
                          </span>
                        </div>
                        <div className="sort-by-dropdown-wrap">
                          <span>
                            Featured <i className="fi-rs-angle-small-down"></i>
                          </span>
                        </div>
                      </div>
                      <div className="sort-by-dropdown">
                        <ul>
                          <li>
                            <a className="active" href="#">
                              Featured
                            </a>
                          </li>
                          <li>
                            <a href="#">Newest</a>
                          </li>
                          <li>
                            <a href="#">Most comments</a>
                          </li>
                          <li>
                            <a href="#">Release Date</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="loop-grid">
                  <div className="row">
                    {commentsData &&
                      commentsData?.map((data, i) => {
                        return (
                          <article
                            className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated"
                            key={i}
                          >
                            <div className="post-thumb">
                              <Link to={"/blog-details/" + data?.slug}>
                                <Image
                                  className="border-radius-15"
                                  src={URL.API_BASE_URL + data?.feature_image}
                                  alt={data?.title}
                                  width={300}
                                  height={200}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/imgs/notfound.png";
                                  }}
                                />
                              </Link>
                              {/* <a href="blog-post-right.html">
                                <img
                                  className="border-radius-15"
                                  src={URL.API_BASE_URL + data?.feature_image}
                                  alt=""
                                />
                              </a> */}
                              {/* <div className="entry-meta">
                                <a
                                  className="entry-meta meta-2"
                                  href="blog-category-grid.html"
                                >
                                  <i className="fi-rs-heart"></i>
                                </a>
                              </div> */}
                            </div>
                            <div className="entry-content-2">
                              {/* <h6 className="mb-10 font-sm">
                                <a
                                  className="entry-meta text-muted"
                                  href="blog-category-grid.html"
                                >
                                  Side Dish
                                </a>
                              </h6> */}
                              <h4 className="post-title blogtitlt mb-15">
                                {/* <a href="#">{data?.title}</a> */}
                                <Link to={"/blog-details/" + data?.slug}>
                                  {data?.title}
                                </Link>
                              </h4>
                              <div className="entry-meta font-xs color-grey mt-10 pb-10">
                                <div>
                                  <span className="post-on mr-10">
                                    {/* 25 April 2022 */}
                                    {moment(data?.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                  {/* <span className="hit-count has-dot mr-10">
                                    126k Views
                                  </span>
                                  <span className="hit-count has-dot">
                                    4 mins read
                                  </span> */}
                                </div>
                              </div>
                            </div>
                          </article>
                        );
                      })}

                    {/* <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-2.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Soups and Stews
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Summer Quinoa Salad Jars with Lemon Dill
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-3.png"
                            alt=""
                          />
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            <i className="fi-rs-link"></i>
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Salad
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Caprese Chicken with Smashed Potatoes
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-4.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Dessert
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Harissa Chickpeas with Whipped Feta
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-5.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Breakfast
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Almond Butter Chocolate Chip Zucchini Bars
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-6.png"
                            alt=""
                          />
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            <i className="fi-rs-picture"></i>
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Vegan
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Smoky Beans & Greens Tacos with Aji Verde
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-1.png"
                            alt=""
                          />
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            <i className="fi-rs-heart"></i>
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Side Dish
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The Intermediate Guide to Healthy Food
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-7.png"
                            alt=""
                          />
                        </a>
                        <div className="entry-meta">
                          <a
                            className="entry-meta meta-2"
                            href="blog-category-grid.html"
                          >
                            <i className="fi-rs-play-alt"></i>
                          </a>
                        </div>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Gluten Free
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Sticky Ginger Rice Bowls with Pickled Veg
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-8.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Side Dish
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Creamy Garlic Sun-Dried Tomato Pasta
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-9.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Dairy Free
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The Absolute Easiest Spinach and Pizza
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-10.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Salad
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Sticky Ginger Rice Bowls with Pickled
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-1.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Soups
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The Best Soft Chocolate Chip Cookies
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-12.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Vegetarian
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            Baked Mozzarella Chicken Rolls
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-13.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            {" "}
                            Dessert{" "}
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The Best Avocado Egg Salad
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-14.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Vegetarian
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The litigants on the screen are not actors
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article className="col-xl-3 col-lg-4 col-md-6 text-center hover-up mb-30 animated">
                      <div className="post-thumb">
                        <a href="blog-post-right.html">
                          <img
                            className="border-radius-15"
                            src="assets/imgs/blog/blog-15.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="entry-content-2">
                        <h6 className="mb-10 font-sm">
                          <a
                            className="entry-meta text-muted"
                            href="blog-category-grid.html"
                          >
                            Vegetarian
                          </a>
                        </h6>
                        <h4 className="post-title mb-15">
                          <a href="blog-post-right.html">
                            The litigants on the screen are not actors
                          </a>
                        </h4>
                        <div className="entry-meta font-xs color-grey mt-10 pb-10">
                          <div>
                            <span className="post-on mr-10">25 April 2022</span>
                            <span className="hit-count has-dot mr-10">
                              126k Views
                            </span>
                            <span className="hit-count has-dot">4 mins read</span>
                          </div>
                        </div>
                      </div>
                    </article> */}
                  </div>
                </div>

                {commentsData?.length > 0 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    className="dataTables_paginate paging_simple_numbers category"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                )}
                {/* <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-start">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <i className="fi-rs-arrow-small-left"></i>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link dot" href="#">
                          ...
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          6
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <i className="fi-rs-arrow-small-right"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <Footer /> */}
    </div>
  );
}

export default Blogs;
