import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeNewCom from "../Component/HomeComponent/HomeNewCom";
import Blogs from "../Component/WebsiteComponent/Blogs";
import BlogsDetails from "../Component/WebsiteComponent/BlogsDetails";
import Brand from "../Component/WebsiteComponent/Brand";
import Checkout from "../Component/WebsiteComponent/Checkout";
import CheckoutAdress from "../Component/WebsiteComponent/CheckoutAdress";
import LastChanse from "../Component/WebsiteComponent/LastChanse";
import Login from "../Component/WebsiteComponent/Login";
import MainCategory from "../Component/WebsiteComponent/MainCategory";
import NewLouch from "../Component/WebsiteComponent/NewLouch";
import ProductDetails from "../Component/WebsiteComponent/ProductDetails";
import Register from "../Component/WebsiteComponent/Register";
import Shopcart from "../Component/WebsiteComponent/Shopcart";
import SubCategory from "../Component/WebsiteComponent/SubCategory";
import SubSubCategory from "../Component/WebsiteComponent/SubSubCategory";

import AuthAdmin from "./AuthAdmin";

function AppRoutes() {
  return (
    // < Switch >
    //   < Route exact path="/" component={UserTable} />
    //   < Route exact path="/login" component={Login} />
    // </Switch>

    // <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomeNewCom />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/brand" element={<Brand />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/new-launch" element={<NewLouch />} />
      <Route path="/last-chance" element={<LastChanse />} />
      <Route path="/shop-cart" element={<Shopcart />} />
      <Route path="/checkout-address" element={<CheckoutAdress />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/blog-details/:id" element={<BlogsDetails />} />
      <Route path="/category-products/:id" element={<MainCategory />} />
      <Route path="/sub-category-product/:id" element={<SubCategory />} />
      <Route path="/category-product/:id" element={<SubSubCategory />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />
      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="/register" element={<Register />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forget-password" element={<ForgetPassword />} />

      <Route path="/" exact element={<AuthAdmin cmp={Home} />} />
      <Route path="/game-play" element={<AuthAdmin cmp={GamePlay} />} />
      <Route path="/my-wallet" element={<AuthAdmin cmp={MyWallet} />} />
      <Route path="/game-rate" element={<AuthAdmin cmp={GameRate} />} />
      <Route path="/contact-us" element={<AuthAdmin cmp={ContactUs} />} />
      <Route path="/add-point" element={<AuthAdmin cmp={AddPoint} />} />
      <Route path="/withdrawal" element={<AuthAdmin cmp={Withdrawal} />} />
      <Route path="/single-game" element={<AuthAdmin cmp={SingleGame} />} />
      <Route path="/jodi" element={<AuthAdmin cmp={Jodi} />} />
      <Route path="/single-pana" element={<AuthAdmin cmp={SinglePana} />} />
      <Route path="/double-pana" element={<AuthAdmin cmp={DoublePana} />} />
      <Route path="/tripal-pana" element={<AuthAdmin cmp={TripalPana} />} />
      <Route path="/half-sigma" element={<AuthAdmin cmp={HalfSigma} />} />
      <Route path="/full-sigma" element={<AuthAdmin cmp={FullSigma} />} />
      <Route path="/bid-history" element={<AuthAdmin cmp={BidHistory} />} />
      <Route path="/win-history" element={<AuthAdmin cmp={WinHistory} />} />
      <Route path="/proccess" element={<AuthAdmin cmp={Proccess} />} />
      <Route path="/success" element={<AuthAdmin cmp={Success} />} />
      <Route path="/failed" element={<AuthAdmin cmp={Failed} />} />
      <Route path="/profile" element={<AuthAdmin cmp={Profile} />} />
      <Route path="/chart" element={<AuthAdmin cmp={ChartList} />} />
      <Route path="/phonePe" element={<AuthAdmin cmp={PhonePe} />} />
      <Route path="/Paytem" element={<AuthAdmin cmp={Paytem} />} />
      <Route path="/googlePay" element={<AuthAdmin cmp={GooglePay} />} />
     
      <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/* <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/*  <Route path="/order-details/:id" element={<AuthAdmin cmp={Tracking} />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/shop-categories" element={<ShopCategories />} />
      <Route path="/shop-cart" element={<ShopCart />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />

      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/choose-shipping-method"
        element={<ChooseShippingMethod />}
      />
      <Route path="/review-your-order" element={<ReviewYourOrder />} />
      <Route path="/choose-payment-method" element={<ChoosePaymentMethod />} />
      <Route path="/buy-now" element={<BuyNowPro />} />
      <Route path="/checkout-complate" element={<CheckoutComplete />} />
      <Route path="/response" element={<Responce />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/products" element={<ProductRight />} />
      <Route path="/searching-product" element={<SearchingProduct />} />
      <Route path="/orders/success" element={<OrderSuccess success={true} />} />
      <Route path="/orders/failed" element={<OrderSuccess success={false} />} />
      <Route path="/order/:id" element={<OrderStatus />} />
      <Route path="/buy-product-order/:id" element={<OrderStatus2 />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route
        path="/refund-and-cancellation-policy"
        element={<RefundandCancellation />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route
        path="/checkout-details"
        element={<AuthAdmin cmp={CheckoutDetails} />}
      />
      <Route
        path="/checkout-details-address"
        element={<AuthAdmin cmp={CheckoutDetails2} />}
      /> */}

      {/* vandor */}
      {/* <Route path="/vendor/login" element={<AuthVendor cmp={Login} />} />
      <Route path="/vendor" element={<AuthVendor cmp={Vendor} />} />
      <Route
        path="/vendor/categories"
        element={<AuthVendor cmp={CategoriesList} />}
      />
      <Route path="/vendor/brand" element={<AuthVendor cmp={Brannd} />} />
     
      <Route path="/vendor/orders" element={<AuthVendor cmp={OrderList} />} />
      <Route
        path="/vendor/transation"
        element={<AuthVendor cmp={Transation} />}
      /> */}
    </Routes>
    // </BrowserRouter>
  );
}

export default AppRoutes;
