import logo from "./logo.svg";
import "./App.css";
 
import AppRoutes from "./Routes/Routes";
import { Provider } from "react-redux";
import store from "./Redux/common/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const ReloadOnScreenChange = () => {
  const location = useLocation();

  useEffect(() => {
    window.location.reload();
  }, [location.pathname]);

  return null;
};
function App() {

  return (
    <div className="">
      {/* <Home /> */}

      <Provider store={store}>
        <AppRoutes />

        {/* <ReloadOnScreenChange/> */}

        <ToastContainer theme="colored" />
      </Provider>
    </div>
  );
}

export default App;
