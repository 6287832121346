// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AboutCom from "./AboutCom";

function HomeNews() {
  const get_footer_all_data_1 = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  console.log(get_footer_all_data_1);
  return (
    <div>
      <div className="container-fluid got show-img">
        <div className="row">
          <div className="col-lg-6 p-0 mt-2 got3">
            <img
              src="https://img.freepik.com/premium-photo/young-promising-business-team-girls-boys-business-image-with-laptops-their-hands_115086-1320.jpg"
              style={{ height: "320px" }}
              className="img-fluid yong"
            />
          </div>

          <div className="col-lg-6 mt-2">
            <div className="per jnbt">
              <h6 className="info_text">
                BECOME A DIGITAL ENTREPRENEURS WITH RIL INDIA
              </h6>
            </div>
            <center>
              <Link to="/register" className="vol p-1 px-3 ml-2 text-center">
                Join Us
              </Link>
            </center>
          </div>
        </div>
      </div>
      <div className="container-fluid got hide-img">
        <div className="row">
          <div className="col-md-6 p-5 mt-5">
            <div className="per">
              <br />
              <br />
              <h1 className="per ">
                BECOME A DIGITAL
                <br />
                ENTREPRENEURS WITH RIL INDIA
              </h1>
            </div>
            <a href={"/register"} className="vol p-1 px-3 ml-2">
              Join Us
            </a>
          </div>
          <div className="col-md-6 p-0 mt-2 got3">
            {/* <img
              src="https://img.freepik.com/premium-photo/young-promising-business-team-girls-boys-business-image-with-laptops-their-hands_115086-1320.jpg"
              style={{ height: "520px", width: "100%" }}
              className="img-fluid"
            /> */}
            <img
              src={
                URL?.API_BASE_URL +
                get_footer_all_data_1?.socialIcon?.registerLogo
              }
              style={{ height: "520px", width: "100%" }}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid got-3">
        <h1 className="fiv text-center mt-5 pt-4">
          <i style={{ fontFamily: "arial" }}>ABOUT RIL INDIA</i>
        </h1>
        <h2 className="text-center mt-5 fiv1 mx-5">
          to build and nurture a National eco system for the micro entrepreneurs
          who has big aim
        </h2>

        <AboutCom />

        <div className="row kou mt-3 px-5">
          <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo"
              src="/roadway.jpg"
            />
          </div>
          {/* <!-- ++ --> */}
          <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo"
              src="/zigzagger.jpg"
            />
          </div>
          {/* <!-- +++ --> */}
          {/* <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo"
              src="https://asort.com/resources/home/images/brands/abg.jpg"
            />
          </div>
        
          <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo"
              src="https://asort.com/resources/home/images/brands/kuefit.jpg"
            />
          </div>
          
          <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo shadow"
              src="https://asort.com/resources/home/images/brands/solasta.jpg"
            />
          </div>
          
          <div className="col-sm-2 col-6">
            <img
              className="pic1 shadow p-3 mb-5 bg-white rounded product_logo"
              src="https://asort.com/resources/home/images/brands/amiiga.jpg"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HomeNews;
