import { useEffect, useState } from "react";
// import { URL } from "@/Redux/common/url";
// import { NextSeo } from "next-seo";
// import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { allapiAction } from "@/Redux/common/action";
// import Api from "@/Redux/common/api";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import Api from "../../Redux/common/api";
import { OTP_BOX } from "../../Redux/common/constant";
// import { OTP_BOX } from "@/Redux/common/constant";

function Register() {
  const [isActive, setIsActive] = useState(false);
  const [loginData, setloginData] = useState({ phone: "", password: "" });
  const [otp_box, setotpBox] = useState("1");
  const [referName, setreferName] = useState("");
  const [registerDetails, setregisterDetails] = useState({});
  const [otp, setotp] = useState("");
  const [referID, setreferID] = useState("");
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "India",
  });

  console.log(userData);
  const handleSubmit = (event) => {
    // event.preventDefault();
    const today = new Date();
    const birthDate = new Date(userData?.dob);
    var agevs = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      agevs--;
    }

    // setIsValid(age >= 18);
    console.log(agevs >= 18);
    return agevs >= 18;
  };
  const dispatch = useDispatch();
  const router = useNavigate();

  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  const hendleDataChange = (e) => {
    const { name, value } = e.target;
    setloginData({ ...loginData, [name]: value });
  };
  console.log(userData);
  const handleRegisterDataChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      router.push("/home");
    }
  });

  const hendleLogin = (e) => {
    e?.preventDefault();
    dispatch(allapiAction.signinSeller(loginData, router));
    return () => {};
  };

  const hendleToSubmitRegi = async (e) => {
    e.preventDefault();
    if (!userData?.fullName) {
      toast.error("Full Name Required");
    } else if (!userData?.phone) {
      toast.error("enter phone");
    } else {
      const response = await Api.post(`${URL.registerUser}`, {
        fullName: userData?.fullName,
        phone: userData?.phone,
      });

      if (response?.data?.success) {
        setotpBox("2");
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      // setotpBox
    }
  };

  const hendlereferCheck = async (e) => {
    setreferID(e);
    try {
      const response = await Api.post(`${URL.referCheck}`, { referBy: e });
      if (response?.data?.success) {
        setreferName(response?.data?.data?.fullName);
      } else {
        setreferName("");
        toast.error("invalid refer code");
      }
    } catch (error) {
      console.log(error);
      toast.error("invalid refer code");
    }
  };

  const getShoeBoxAction = (data) => {
    return { type: OTP_BOX, payload: data };
  };

  const hendleChangeEmail = () => {
    dispatch(getShoeBoxAction(false));
    setotpBox("1");
  };

  const handleLookup = async () => {
    try {
      if (userData?.zip.trim() === "") {
        toast.error("please enter dob");
        return; // Don't fetch if pin code is empty
      }
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${userData?.zip}`
      );
      const data = await response.json();

      if (
        data &&
        data[0] &&
        data[0].PostOffice &&
        data[0].PostOffice.length > 0
      ) {
        const firstOffice = data[0].PostOffice[0];
        // setCity(firstOffice.District);
        // setState(firstOffice.State);
        setUserData({
          ...userData,
          city: firstOffice.District,
          state: firstOffice.State,
        });
        // setTimeout(() => {
        //   setUserData({ ...userData, state: firstOffice.State });
        // }, 300);
        // setError('');
      } else {
        toast.error("City and state information not found for this pin code.");
        setUserData({
          ...userData,
          city: "",
          state: "",
        });
        // setError('City and state information not found for this pin code.');
        // setCity('');
        // setState('');
      }
    } catch (error) {
      toast.error("Error fetching data. Please try again.");
      // setError('Error fetching data. Please try again.');
      // setCity('');
      // setState('');
    }
  };

  const hendleverifysec = async () => {
    if (!userData?.fullName) {
      toast.error("Full Name Required");
    } else if (!userData?.email) {
      toast.error("Email Required");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userData?.email)
    ) {
      toast.error("Email  Invalid");
    } else if (!userData?.confirmPassword) {
      toast.error("Confirm Password Required");
    } else if (!userData?.password) {
      toast.error("Password Required");
    } else if (userData?.password !== userData?.confirmPassword) {
      toast.error("Password not Match");
    } else if (!referID) {
      toast.error("please enter refer id");
    } else if (!userData?.dob) {
      toast.error("please enter dob");
    } else if (!handleSubmit(userData?.dob)) {
      toast.error(
        "Sorry, registration is only available to users who are 18 years of age or older. Please come back once you meet the age requirement"
      );
    } else if (!userData?.city) {
      toast.error("please enter city");
    } else if (!userData?.state) {
      toast.error("please enter state");
    } else if (!userData?.zip) {
      toast.error("please enter zip");
    } else {
      const response = await Api.post(`${URL.otpVerifyuser}`, {
        fullName: userData?.fullName,
        phone: userData?.phone,
        password: userData?.password,
        dob: userData?.dob,
        city: userData?.city,
        state: userData?.state,
        zip: userData?.zip,
        country: userData?.country,
        email: userData?.email,
        referBy: referID,
        otp: otp,
      });
      // console.log(response);
      if (response?.data?.success) {
        setotpBox("3");
        setregisterDetails(response?.data?.data);
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];
  const urltet = URL.API_BASE_URL_2 + "/login";

  return (
    <div>
      <div className={otp_box == "2" ? "ott " : "nottt "}>
        <div class={isActive ? "container5  sign-up-mode" : "container5"}>
          <div class="forms-container5">
            <div class="signin-signup">
              <form class="loginff sign-in-form">
                {otp_box !== "3" && <h2 class="title">Sign up</h2>}
                <div class="row">
                  {/* <input
                  type="date"
                  required=""
                  name="dob"
                  placeholder="Enter dob"
                  onChange={(e) => {
                    handleRegisterDataChange(e);
                  }}
                />
                <button onClick={(e) => handleSubmit(e)}>dshfjkh</button> */}
                  {otp_box == "1" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-user"></i>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          name="fullName"
                          onChange={(e) => handleRegisterDataChange(e)}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "1" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-phone"></i>
                        <input
                          type="number"
                          placeholder="Enter Phone"
                          name="phone"
                          onChange={(e) => handleRegisterDataChange(e)}
                        />
                      </div>
                    </div>
                  )}

                  {otp_box == "1" && (
                    <div class="col-sm-12">
                      <button
                        type="button"
                        className="btn rrbb"
                        onClick={(e) => hendleToSubmitRegi(e)}
                      >
                        Submit & Register
                      </button>
                    </div>
                  )}

                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-key"></i>
                        <input
                          required=""
                          type="number"
                          name="otp"
                          placeholder="Enter Otp"
                          onChange={(e) => {
                            setotp(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-users"></i>
                        <input
                          type="text"
                          required=""
                          name="referBy"
                          placeholder="Enter refer id"
                          onChange={(e) => {
                            hendlereferCheck(e?.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-users"></i>
                        <input
                          type="text"
                          required=""
                          name="referBy"
                          placeholder="Enter refer name"
                          value={referName}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-calendar-check-o"></i>
                        <input
                          type="date"
                          required=""
                          name="dob"
                          placeholder="Enter dob"
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-envelope"></i>
                        <input
                          type="text"
                          required=""
                          name="email"
                          placeholder="Enter Email"
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-key"></i>
                        <input
                          required=""
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-key"></i>
                        <input
                          required=""
                          type="password"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-map-marker"></i>
                        <input
                          required=""
                          type="number"
                          name="zip"
                          placeholder="Enter zip"
                          onBlur={handleLookup}
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-building"></i>
                        <input
                          required=""
                          type="tesxt"
                          name="city"
                          value={userData?.city}
                          placeholder="Enter city"
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-building"></i>
                        <select
                          name="state"
                          value={userData?.state}
                          onChange={(e) => {
                            handleRegisterDataChange(e);
                          }}
                        >
                          <option value="">Select a state</option>
                          {states.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {otp_box == "2" && (
                    <div class="col-sm-6">
                      <div className="input-field">
                        <i className="fas fa-building"></i>
                        <input
                          required=""
                          type="text"
                          name="country"
                          value={userData?.country}
                          placeholder="Enter country"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {otp_box == "2" && (
                  <div className="btn-group-vertical">
                    <button
                      type="button"
                      className="btn btn-fill-out btn-block hover-up font-weight-bold"
                      name="login"
                      onClick={() => {
                        hendleverifysec();
                      }}
                    >
                      Verify Otp
                    </button>
                    <button
                      className="changeEmail btn btn-success mt-2"
                      onClick={() => {
                        hendleChangeEmail();
                      }}
                    >
                      Change Email
                    </button>
                  </div>
                )}
                {otp_box == "3" && (
                  <div className="registersuccessBox">
                    <h5>
                      CONGRATULATIONS DEAR {registerDetails?.fullName} YOUR ID
                      IS
                      {registerDetails?.referId} SUCCESSFULLY DONE...WE WISH ALL
                      THE BEST FOR YOUR BRIGHT FUTURE WITH RESOLVED IMPROVE LIFE
                      INDIA MARKETING PRIVATE LIMITED FAMILY..
                    </h5>
                    <h6>
                      YOUR USERNAME: {registerDetails?.referId} AND PASSWORD:
                      {registerDetails?.passwordPlan}
                    </h6>
                  </div>
                )}
              </form>
              <form class="loginff sign-up-form ">
                <h2 class="title">Sign in</h2>

                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Enter You ID"
                    name="phone"
                    onChange={(e) => hendleDataChange(e)}
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => hendleDataChange(e)}
                  />
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => hendleLogin(e)}
                >
                  Sign in
                </button>

                {/* <div class="input-field  ">
                <i class="fas fa-user"></i>
                <input type="text" placeholder="Username" />
              </div>

              <div class="input-field  ">
                <i class="fas fa-envelope"></i>
                <input type="email" placeholder="Email" />
              </div>

              <div class="input-field">
                <i class="fas fa-lock"></i>
                <input type="password" placeholder="Password" />
              </div>
              <input type="submit" class="btn" value="Sign up" /> */}
                {/* <p class="social-text">Or Sign up with social platforms</p>
              <div class="social-media">
                <a href="#" class="social-icon">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#" class="social-icon">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#" class="social-icon">
                  <i class="fab fa-google"></i>
                </a>
                <a href="#" class="social-icon">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div> */}
              </form>
            </div>
          </div>

          <div class="panels-container5">
            <div class="panel left-panel">
              <div class="content">
                <h3>New here ?</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Debitis, ex ratione. Aliquid!
                </p>
                <button
                  class="btn transparent"
                  id="sign-up-btn"
                  onClick={() => {
                    setIsActive(true);
                    setotpBox(1);
                  }}
                >
                  Sign in
                </button>
              </div>
              <img
                src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
                class="simage"
                alt=""
              />
            </div>
            <div class="panel right-panel">
              <div class="content">
                <h3>One of us ?</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nostrum laboriosam ad deleniti.
                </p>
                <button
                  class="btn transparent"
                  id="sign-in-btn"
                  onClick={() => {
                    setIsActive(false);
                    setotpBox(1);
                  }}
                >
                  Sign up
                </button>
              </div>
              <img
                src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
                class="simage"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
